:root {
  --bg-color: #2c3e50;
  --text-color: ghostwhite;
  --bg-section: #212f3c;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}
* {
  margin: 0;
  padding: 0;
}

.App {
  width: 600px;
  height: 440px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 20vh; */
  font-family: sans-serif;
  text-align: center;
  border: none;
  padding: 2em;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.session_break_wrapper {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
}

#break {
  background-color: var(--bg-section);
  /* align-items: center; */
  width: 100px;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

#session {
  background-color: var(--bg-section);
  /* align-items: center; */
  width: 100px;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

#timer {
  /* background-color: var(--bg-section); */
  /* align-items: center; */
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.65em;
}

.paused_timer {
  background-color: var(--bg-section);
}
.playing_timer {
  background-color: #196f3d;
}

.paused_timer_label {
  content: "PAUSED";
}
.session_timer_label {
  content: "SESSION";
}
.break_timer_label {
  content: "BREAK";
}

#time-left {
  font-size: 1.7em;
}

#controls {
  background-color: var(--bg-section);
  /* align-items: center; */
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

#session-label {
  text-transform: uppercase;
  font-weight: bold;
}

#break-label {
  text-transform: uppercase;
  font-weight: bold;
}

#timer-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
}

button {
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
  margin: 0.7em;
  text-transform: uppercase;
}

p {
  padding: 0.4em;
}
